
import fetch from 'auth/FetchInterceptor'
const whiteLabelService = {};

whiteLabelService.updateWhiteLabel = function (data) {
    return fetch({
      url: '/user/whitelabel',
      method: 'post',
      data: data
    })
  }

  whiteLabelService.getDataFromDomain = function (data) {
    return fetch({
      url: '/search_by_domain',
      method: 'post',
      data: data
    })
  }




  whiteLabelService.getWhiteLabel = function (data) {
    return fetch({
      url: '/user/get_whitelabel_data',
      method: 'get',
      data: data
    })
  }

  whiteLabelService.getWhiteLabelUsers = function () {
    return fetch({
      url: '/whitelabels',
      method: 'get'
    })
  }

  whiteLabelService.addWhiteLabelUsers = function (data) {
    return fetch({
      url: '/whitelabel/create',
      method: 'post',
      data:data
    })
  }

export default whiteLabelService;

