import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import store from "./store";
import history from "./history";
import Layouts from "./layouts";
import { THEME_CONFIG } from "./configs/AppConfig";
import "./lang";
import whiteLabelService from "services/WhiteLabelService";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  useEffect(() => {
    if (!window.location.host.includes("voicegpt.redub.pro")) {
      const post = { domain_name: window.location.host };
      whiteLabelService
        .getDataFromDomain(post)
        .then((resp) => {
          const whitelabel = resp?.data;
          if (whitelabel) {
            localStorage.setItem("whiteLabel", JSON.stringify(whitelabel));
          }
          document.title = whitelabel.brand_name;
          const link = document.createElement("link");
          link.rel = "icon";
          link.href = whitelabel.brand_favicon;
          document.head.appendChild(link);
        })
        .catch(() => {
          document.title = "VoiceGPT AI";
        });
    } else {
      document.title = "VoiceGPT AI";
    }
  }, []);
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter history={history}>
          <ThemeSwitcherProvider
            themeMap={themes}
            defaultTheme={THEME_CONFIG.currentTheme}
            insertionPoint="styles-insertion-point"
          >
            <Layouts />
          </ThemeSwitcherProvider>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
