import customFetch from "auth/FetchInterceptor";

const authService = {};

authService.login = function (data) {
   return customFetch({
    url: '/login',
    method: 'post',
    data: data,
  });
};

authService.logout = function () {
  return customFetch({
    url: "/logout",
    method: "get",
  });
};

authService.forgotPassword = function (data) {
  return customFetch({
    url: "/forgot-password",
    method: "post",
    data: data,
  });
};

authService.resetPassword = function (data) {
  return customFetch({
    url: "/reset-password",
    method: "post",
    data: data,
  });
};


authService.changePassword = function (data) {
	return customFetch({
	  url: "/change-password",
	  method: "post",
	  data: data,
	});
  };

  authService.createUser = function (data) {
    return customFetch({
      url: "/user/create",
      method: "post",
      data: data,
    });
    };
    authService.createWhitelabel = function (data) {
      return customFetch({
        url: "/user/createWhitelabel",
        method: "post",
        data: data,
      });
      };

export default authService;
